import { useNavigate } from "react-router-dom";
import "./NotFoundPage.css";

function NotFoundPage() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <section className="not-found">
      <p className="not-found__code">404</p>
      <h2 className="not-found__title">Страница не найдена</h2>
      <button className="not-found__link page__button" onClick={handleGoBack}>
        Назад
      </button>
    </section>
  );
}

export default NotFoundPage;
