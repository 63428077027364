import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import Logo from "../Logo/Logo";
import Navigation from "../Navigation/Navigation";
import AccountLink from "../AccountLink/AccountLink";

function Header({ onBurgerClick, loggedIn }) {
  const location = useLocation();

  return (
    <header
      className={`header ${
        location.pathname === "/" ? "header_type_main" : ""
      }`}
    >
      <Logo />
      {loggedIn ? (
        <>
          <div className="header__container header__container_type_movies">
            <Navigation />
            <AccountLink />
          </div>
          <button
            className="header__burger-button page__button"
            type="button"
            onClick={onBurgerClick}
          ></button>
        </>
      ) : (
        <div className="header__container">
          <nav className="header__menu">
            <Link
              className="header__link header__link_type_registration page__link"
              to="/signup"
            >
              Регистрация
            </Link>
            <Link
              className="header__link header__link_type_login page__link"
              to="/signin"
            >
              Войти
            </Link>
          </nav>
        </div>
      )}
    </header>
  );
}

export default Header;
