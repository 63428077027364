import { Link } from "react-router-dom";

import "./Logo.css";

function Logo() {
  return (
    <Link to="/" className="logo page__link">
      <div className="logo__img"></div>
    </Link>
  );
}

export default Logo;
