import "./MoviesCardList.css";
import MoviesCard from "../MoviesCard/MoviesCard";

function MoviesCardList({
  movies,
  savedMoviesUser,
  onMovieSave,
  onMovieDelete,
  isMoreButtonVisible = false,
  onMoreButtonClick,
}) {
  const moreButtonClassName = `movies-cards__button-more page__button ${
    isMoreButtonVisible && "movies-cards__button-more_active"
  }`;

  return (
    <section className="movies-cards">
      <ul
        className={`movies-cards__list ${
          movies.length > 3 ? "movies-cards__list_space-evenly" : ""
        }`}
      >
        {movies.map((movie) => (
          <MoviesCard
            key={movie.id || movie.movieId}
            movie={movie}
            savedMoviesUser={savedMoviesUser}
            onMovieSave={onMovieSave}
            onMovieDelete={onMovieDelete}
          />
        ))}
      </ul>
      <button
        className={moreButtonClassName}
        onClick={onMoreButtonClick}
        type="button"
      >
        Ещё
      </button>
    </section>
  );
}

export default MoviesCardList;
