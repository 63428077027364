import React from "react";
import { Link } from "react-router-dom";
import "./Register.css";
import HeaderAuthorization from "../HeaderAuthorization/HeaderAuthorization";
import { useFormValidation } from "../../hooks/useFormValidation";

function Register({
  title,
  onRegister,
  isSuccess,
  errorStatus: { message, type },
}) {
  const { resetForm, values, handleChange, errors, isValid } =
    useFormValidation();
  const isDisabled = !isValid || isSuccess;

  React.useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onRegister({
      name: values.name,
      email: values.email,
      password: values.password,
    });
  };

  return (
    <section className="register">
      <HeaderAuthorization title={title} />
      <form
        className="register__form"
        name="register-form"
        onSubmit={handleSubmit}
      >
        <label className="register__form-label">
          <span className="register__form-label-text">Имя</span>
          <input
            className="register__form-input register__form-input_type_name"
            type="text"
            id="name"
            name="name"
            placeholder=""
            minLength="2"
            maxLength="30"
            required
            value={values.name || ""}
            onChange={handleChange}
          />
          <span
            className={`register__form-error ${
              errors.name && "register__form-error_visible"
            }`}
            id="name-error"
          >
            {errors.name
              ? "Имя должно содержать от двух до тридцати символов"
              : ""}
          </span>
        </label>

        <label className="register__form-label">
          <span className="register__form-label-text">E-mail</span>
          <input
            className="register__form-input register__form-input_type_email"
            type="email"
            id="email"
            name="email"
            placeholder=""
            minLength="6"
            maxLength="30"
            required
            value={values.email || ""}
            onChange={handleChange}
          />
          <span
            className={`register__form-error ${
              errors.email && "register__form-error_visible"
            }`}
            id="email-error"
          >
            {errors.email || ""}
          </span>
        </label>

        <label className="register__form-label">
          <span className="register__form-label-text">Пароль</span>
          <input
            className="register__form-input register__form-input_type_password"
            type="password"
            id="password"
            name="password"
            placeholder=""
            minLength="6"
            required
            value={values.password || ""}
            onChange={handleChange}
          />
          <span
            className={`register__form-error ${
              errors.password && "register__form-error_visible"
            }`}
            id="password-error"
          >
            {errors.password
              ? "Пароль должен содержать не менее 6 символов"
              : ""}
          </span>
        </label>

        <span className={`form__message form__message_type_${type}`}>
          {message}
        </span>

        <button
          className={`register__form-submit-button page__button ${
            !isValid && "register__form-submit-button_disabled"
          }`}
          disabled={isDisabled}
          type="submit"
        >
          Зарегистрироваться
        </button>
      </form>

      <p className="register__form-caption">
        Уже зарегистрированы?
        <Link className="register__link-signin page__link" to="/signin">
          Войти
        </Link>
      </p>
    </section>
  );
}

export default Register;
