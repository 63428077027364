import "../Portfolio/Portfolio.css";

export default function Portfolio() {
  return (
    <section className="portfolio">
      <h2 className="portfolio__title">Портфолио</h2>
      <nav>
        <ul className="portfolio__items">
          <li className="portfolio__item">
            <a
              className="portfolio__link page__link"
              href="https://ammosoff.github.io/how-to-learn/"
              target="_blank"
              rel="noreferrer"
            >
              Статичный сайт
              <span className="portfolio__icon"></span>
            </a>
          </li>
          <li className="portfolio__item">
            <a
              className="portfolio__link page__link"
              href="https://ammosoff.github.io/russian-travel/"
              target="_blank"
              rel="noreferrer"
            >
              Адаптивный сайт
              <span className="portfolio__icon"></span>
            </a>
          </li>
          <li className="portfolio__item">
            <a
              className="portfolio__link page__link"
              href="https://ammosoff.github.io/react-mesto-auth"
              target="_blank"
              rel="noreferrer"
            >
              Одностраничное приложение
              <span className="portfolio__icon"></span>
            </a>
          </li>
        </ul>
      </nav>
    </section>
  );
}
