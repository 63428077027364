import { Link } from "react-router-dom";
import "./AccountLink.css";

function AccountLink({ isOpen, onClose }) {
  return (
    <div className={`account-link ${isOpen ? "" : "account-link_hidden"}`}>
      <Link
        className="account-link__link account-link_type_burger page__link"
        to="/profile"
        onClick={onClose}
      >
        <span className="account-link__icon"></span>
        Аккаунт
      </Link>
    </div>
  );
}

export default AccountLink;
