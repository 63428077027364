import React from "react";

import "./FilterCheckbox.css";
function FilterCheckbox({ checkboxStatus, onCheckboxChange }) {
  const handleCheckboxChange = (e) => {
    onCheckboxChange(e.target.checked);
  };

  return (
    <div className="filter-checkbox">
      <label className="filter-checkbox__container">
        <input
          type="checkbox"
          className="filter-checkbox__toggle"
          checked={checkboxStatus}
          onChange={handleCheckboxChange}
        />
        <span className="filter-checkbox__tumbler"></span>
      </label>
      <p className="filter-checkbox__text">Короткометражки</p>
    </div>
  );
}

export default FilterCheckbox;
