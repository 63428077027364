export function conversionDuration(duration) {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  if (hours === 0) {
    return `${minutes}м`;
  } else {
    return `${hours}ч ${minutes}м`;
  }
}

export function filterMovies(movies, setSearch, checkboxStatus) {
  let moviesToFilter = movies;
  let result;
  if (checkboxStatus) {
    moviesToFilter = moviesToFilter.filter((movie) => movie.duration <= 40);
  }
  result = moviesToFilter.filter((movie) => {
    return movie.nameRU.toLowerCase().indexOf(setSearch.toLowerCase()) !== -1;
  });
  return result;
}

export const CARD_RENDER_PARAMS_BY_DEVICE = {
  default: {
    width: 1280,
    cards: {
      total: 16,
      more: 4,
    },
  },
  desktop: {
    width: 991,
    cards: {
      total: 12,
      more: 3,
    },
  },
  tablet: {
    width: 768,
    cards: {
      total: 8,
      more: 2,
    },
  },
  mobile: {
    width: 320,
    cards: {
      total: 5,
      more: 2,
    },
  },
};
