import { useCallback, useState } from "react";
import isEmail from "validator/es/lib/isEmail";

export function useFormValidation() {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    let validationError = "";

    if (name === "email") {
      if (!isEmail(value)) {
        validationError = "Некорректный e-mail адрес";
      }
    } else {
      validationError = target.validationMessage;
    }

    setErrors({ ...errors, [name]: validationError });

    setValues({ ...values, [name]: value });
    setIsValid(target.closest("form").checkValidity() && !validationError);
  };

  const resetForm = useCallback(
    (newValues = {}, newErrors = {}, newIsValid = false) => {
      setValues(newValues);
      setErrors(newErrors);
      setIsValid(newIsValid);
    },
    [setValues, setErrors, setIsValid]
  );

  return {
    values,
    errors,
    isValid,
    handleChange,
    setValues,
    setErrors,
    setIsValid,
    resetForm,
  };
}
