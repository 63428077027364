class MoviesApi {
  constructor({ baseUrl }) {
    this._baseUrl = baseUrl;
  }

  _getRequestState(res) {
    if (res.ok) {
      return res.json();
    }

    return Promise.reject(`Ошибка: ${res.status}`);
  }

  _request(url, options) {
    return fetch(`${this._baseUrl}${url}`, options).then(this._getRequestState);
  }

  _getAuthorizationHeaders() {
    return {
      "Content-Type": "application/json",
    };
  }

  getMovies() {
    return this._request("/beatfilm-movies", {
      headers: this._getAuthorizationHeaders(),
    });
  }
}

const moviesApi = new MoviesApi({
  baseUrl: "https://api.nomoreparties.co",
});

export default moviesApi;
