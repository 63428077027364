import { NavLink } from "react-router-dom";
import "./BurgerMenu.css";
import Popup from "../Popup/Popup";
import AccountLink from "../AccountLink/AccountLink";

function BurgerMenu({ isOpen, onClose }) {
  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <section className={`burger-menu ${isOpen ? "burger-menu_active" : ""}`}>
        <button
          className="burger-menu__button-close page__button"
          onClick={onClose}
        ></button>
        <nav className="burger-menu__nav">
          <ul className="burger-menu__list">
            <li>
              <NavLink
                className={({ isActive }) =>
                  `burger-menu__item page__link ${
                    isActive ? "burger-menu__item_active" : ""
                  }`
                }
                to="/"
                onClick={onClose}
              >
                Главная
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  `burger-menu__item page__link ${
                    isActive ? "burger-menu__item_active" : ""
                  }`
                }
                to="/movies"
                onClick={onClose}
              >
                Фильмы
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  `burger-menu__item page__link ${
                    isActive ? "burger-menu__item_active" : ""
                  }`
                }
                to="/saved-movies"
                onClick={onClose}
              >
                Сохранённые фильмы
              </NavLink>
            </li>
          </ul>
        </nav>
        <AccountLink isOpen={true} onClose={onClose} />
      </section>
    </Popup>
  );
}

export default BurgerMenu;
