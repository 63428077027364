import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Profile.css";
import { useFormValidation } from "../../hooks/useFormValidation";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
function Profile({
  onProfileEdit,
  onSignOut,
  isSuccess,
  errorStatus: { message, type },
}) {
  const currentUser = useContext(CurrentUserContext);
  const {
    values,
    errors,
    handleChange,
    isValid,
    setValues,
    resetForm,
    setIsValid,
  } = useFormValidation();
  const [isActived, setIsActived] = useState(false);
  const isDisabled = !isValid || isSuccess;

  useEffect(() => {
    if (currentUser) {
      setValues({
        name: currentUser.name,
        email: currentUser.email,
      });
    }
  }, [currentUser, setValues]);

  useEffect(() => {
    if (
      currentUser.name === values.name &&
      currentUser.email === values.email
    ) {
      setIsValid(false);
    }
  }, [setIsValid, values, currentUser]);

  useEffect(() => {
    if (currentUser) {
      resetForm(currentUser, {}, false);
    }
  }, [currentUser, resetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsActived(false);
    onProfileEdit({
      name: values.name,
      email: values.email,
    });
  };

  const handleChangeUser = (e) => {
    e.preventDefault();
    resetForm(currentUser, {}, false);
    setIsActived(true);
  };

  return (
    <section className="profile">
      <h2 className="profile__title">Привет, {currentUser.name}!</h2>
      <form
        className="profile__form"
        name="profile-form"
        onSubmit={handleSubmit}
        noValidate
      >
        <label className="profile__form-label">
          <span className="profile__form-label-text">Имя</span>
          <input
            className="profile__form-input profile__form-input_type_name"
            type="text"
            id="name"
            name="name"
            placeholder=""
            minLength="2"
            maxLength="30"
            required
            value={values.name || ""}
            onChange={handleChange}
            disabled={!isActived}
          />
        </label>
        <span
          className={`profile__form-error ${
            errors.name && "profile__form-error_visible"
          }`}
          id="name-error"
        >
          {errors.name ? "Имя должно содержать от 2 до 30 символов" : ""}
        </span>

        <label className="profile__form-label">
          <span className="profile__form-label-text">E-mail</span>
          <input
            className="profile__form-input profile__form-input_type_email"
            type="email"
            id="email"
            name="email"
            placeholder=""
            minLength="6"
            maxLength="30"
            required
            value={values.email || ""}
            onChange={handleChange}
            disabled={!isActived}
          />
        </label>

        <span
          className={`profile__form-error ${
            errors.email && "profile__form-error_visible"
          }`}
          id="email-error"
        >
          {errors.email || ""}
        </span>

        <span className={`form__message form__message_type_${type}`}>
          {message}
        </span>

        {!isActived ? (
          <button
            className="profile__form-submit-button page__button"
            type="button"
            onClick={handleChangeUser}
          >
            Редактировать
          </button>
        ) : (
          <button
            className={`profile__form-submit-button page__button ${
              isDisabled && "profile__form-submit-button_disabled"
            }`}
            type="submit"
            disabled={isDisabled}
          >
            Сохранить
          </button>
        )}
      </form>
      <Link
        className="profile__button-logout page__link"
        to="/"
        onClick={onSignOut}
      >
        Выйти из аккаунта
      </Link>
    </section>
  );
}

export default Profile;
