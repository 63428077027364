import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import "./App.css";
import Header from "../Header/Header";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import Main from "../Main/Main";
import Movies from "../Movies/Movies";
import SavedMovies from "../SavedMovies/SavedMovies";
import Profile from "../Profile/Profile";
import Register from "../Register/Register";
import Login from "../Login/Login";
import Footer from "../Footer/Footer";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import mainApi from "../../utils/MainApi";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import ProtectedRouteElement from "../ProtectedRoute/ProtectedRoute";
import * as auth from "../../utils/auth";

function App() {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(false);
  const [tokenCheck, setTokenCheck] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [savedMoviesUser, setsavedMoviesUser] = useState([]);

  const [isSuccessRegister, setIsSuccessRegister] = useState(false);
  const [isErrorRegister, setIsErrorRegister] = useState({});
  const [isErrorLogin, setIsErrorLogin] = useState({});
  const [isSuccessLogin, setIsSuccessLogin] = useState(false);
  const [isSuccessProfile, setIsSuccessProfile] = useState(false);
  const [isErrorProfile, setIsErrorProfile] = useState({});

  const handleBurgerMenuClick = () => {
    setBurgerMenuOpen(true);
  };

  const handleBurgerMenuClose = () => {
    setBurgerMenuOpen(false);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");

      auth
        .checkToken(token)
        .then((res) => {
          if (res) {
            setLoggedIn(true);
            setTokenCheck(true);
            setCurrentUser(res);
          }
        })
        .catch((err) => {
          setLoggedIn(false);
          setTokenCheck(true);
          localStorage.clear();
          localStorage.removeItem("token");
          setCurrentUser({});
          navigate("/");
          console.log(err);
        });
    } else {
      setLoggedIn(false);
      setTokenCheck(true);
    }
  }, [navigate, loggedIn]);

  const handleRegister = ({ name, email, password }) => {
    setIsSuccessRegister(true);
    auth
      .register(name, email, password)
      .then(() => {
        handleLogin({
          email,
          password,
        });
        navigate("/movies", { replace: true });
      })
      .catch((err) => {
        if (err === "Ошибка: 400") {
          setIsErrorRegister({
            message: "Переданы некорректные данные пользователя",
            type: "error",
          });
        } else if (err === "Ошибка: 409") {
          setIsErrorRegister({
            message: "Пользователь с таким e-mail уже существует",
            type: "error",
          });
        } else {
          setIsErrorRegister({
            message: "При регистрации произошла ошибка",
            type: "error",
          });
        }
      })
      .finally(() => {
        setIsSuccessRegister(false);
      });
  };

  const handleLogin = ({ email, password }) => {
    setIsSuccessLogin(true);
    auth
      .authorize(email, password)
      .then((res) => {
        if (res.token) {
          setLoggedIn(true);
          localStorage.setItem("token", res.token);
          navigate("/movies", { replace: true });
        }
      })
      .catch((err) => {
        setLoggedIn(false);
        if (err === "Ошибка: 400") {
          setIsErrorLogin({
            message: "Переданы некорректные данные пользователя",
            type: "error",
          });
        } else if (err === "Ошибка: 401") {
          setIsErrorLogin({
            message: "Неправильные почта или пароль",
            type: "error",
          });
        } else {
          setIsErrorLogin({
            message: "При авторизации произошла ошибка",
            type: "error",
          });
        }
      })
      .finally(() => {
        setIsSuccessLogin(false);
      });
  };

  const handleProfileEdit = ({ name, email }) => {
    setIsSuccessProfile(true);
    setIsErrorProfile({});
    mainApi
      .setUserInfo(name, email)
      .then((userInfo) => {
        setCurrentUser(userInfo);
        setIsErrorProfile({
          message: "Профиль успешно обновлён",
          type: "success",
        });
      })
      .catch((err) => {
        if (err === "Ошибка: 400") {
          setIsErrorProfile({
            message: "Переданы некорректные данные пользователя",
            type: "error",
          });
        } else if (err === "Ошибка: 404") {
          setIsErrorProfile({
            message: "Пользователь с указанным id не найден",
            type: "error",
          });
        } else if (err === "Ошибка: 409") {
          setIsErrorProfile({
            message: "Пользователь с указанным email уже есть",
            type: "error",
          });
        } else {
          setIsErrorProfile({
            message: "При обновлении профиля произошла ошибка",
            type: "error",
          });
        }
      })
      .finally(() => {
        setIsSuccessProfile(false);
      });
  };

  const handleSignOut = () => {
    setLoggedIn(false);
    localStorage.clear();
    localStorage.removeItem("token");
    setCurrentUser({});
    navigate("/");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      mainApi
        .getSavedMovies(token)
        .then((data) => {
          setsavedMoviesUser(data.filter((i) => i.owner === currentUser._id));
        })
        .catch((err) => console.log(err));
    }
  }, [currentUser]);

  const handleMovieSave = (movie) => {
    const token = localStorage.getItem("token");
    mainApi
      .saveMovie({ movie, token })
      .then((newSavedMovie) => {
        setsavedMoviesUser((movies) => [newSavedMovie, ...movies]);
      })
      .catch((err) => console.log(err));
  };

  const handleMovieDelete = (movie) => {
    const token = localStorage.getItem("token");
    mainApi
      .deleteMovie({ movie, token })
      .then(() => {
        setsavedMoviesUser((movies) =>
          movies.filter((m) => m._id !== movie._id)
        );
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {tokenCheck && (
        <CurrentUserContext.Provider value={currentUser}>
          <div className="page">
            <div className="page__content">
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Header
                        onBurgerClick={handleBurgerMenuClick}
                        loggedIn={loggedIn}
                      />
                      <Main />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/movies"
                  element={
                    <ProtectedRouteElement
                      loggedIn={loggedIn}
                      element={
                        <>
                          <Header
                            onBurgerClick={handleBurgerMenuClick}
                            loggedIn={loggedIn}
                          />
                          <Movies
                            savedMoviesUser={savedMoviesUser}
                            onMovieSave={handleMovieSave}
                            onMovieDelete={handleMovieDelete}
                          />
                          <Footer />
                        </>
                      }
                    />
                  }
                />
                <Route
                  path="/saved-movies"
                  element={
                    <ProtectedRouteElement
                      loggedIn={loggedIn}
                      element={
                        <>
                          <Header
                            onBurgerClick={handleBurgerMenuClick}
                            loggedIn={loggedIn}
                          />
                          <SavedMovies
                            savedMoviesUser={savedMoviesUser}
                            onMovieDelete={handleMovieDelete}
                          />
                          <Footer />
                        </>
                      }
                    />
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRouteElement
                      loggedIn={loggedIn}
                      element={
                        <>
                          <Header
                            onBurgerClick={handleBurgerMenuClick}
                            loggedIn={loggedIn}
                          />
                          <Profile
                            onProfileEdit={handleProfileEdit}
                            onSignOut={handleSignOut}
                            isSuccess={isSuccessProfile}
                            errorStatus={isErrorProfile}
                          />
                        </>
                      }
                    />
                  }
                />
                <Route
                  path="/signup"
                  element={
                    loggedIn ? (
                      <Navigate to="/movies" />
                    ) : (
                      <Register
                        title="Добро пожаловать!"
                        onRegister={handleRegister}
                        isSuccess={isSuccessRegister}
                        errorStatus={isErrorRegister}
                      />
                    )
                  }
                />
                <Route
                  path="/signin"
                  element={
                    loggedIn ? (
                      <Navigate to="/movies" />
                    ) : (
                      <Login
                        title="Рады видеть!"
                        onLogin={handleLogin}
                        isSuccess={isSuccessLogin}
                        errorStatus={isErrorLogin}
                      />
                    )
                  }
                />{" "}
                <Route
                  path="/signup"
                  element={
                    loggedIn ? (
                      <Navigate to="/movies" />
                    ) : (
                      <Register
                        title="Добро пожаловать!"
                        onRegister={handleRegister}
                        isSuccess={isSuccessRegister}
                        errorStatus={isErrorRegister}
                      />
                    )
                  }
                />
                <Route
                  path="/signin"
                  element={
                    loggedIn ? (
                      <Navigate to="/movies" />
                    ) : (
                      <Login
                        title="Рады видеть!"
                        onLogin={handleLogin}
                        isSuccess={isSuccessLogin}
                        errorStatus={isErrorLogin}
                      />
                    )
                  }
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
              <BurgerMenu
                isOpen={isBurgerMenuOpen}
                onClose={handleBurgerMenuClose}
              />
            </div>
          </div>
        </CurrentUserContext.Provider>
      )}
    </>
  );
}

export default App;
