import "./HeaderAuthorization.css";
import Logo from "../Logo/Logo";

function HeaderAuthorization({ title }) {
  return (
    <div className="header-authorization">
      <Logo />
      <h2 className="header-authorization__title">{title}</h2>
    </div>
  );
}

export default HeaderAuthorization;
