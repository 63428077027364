import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SearchForm from "../SearchForm/SearchForm";
import MoviesCardList from "../MoviesCardList/MoviesCardList";
import Preloader from "../Preloader/Preloader";
import moviesApi from "../../utils/MoviesApi";
import { filterMovies } from "../../utils/utils";
import { CARD_RENDER_PARAMS_BY_DEVICE } from "../../utils/utils";

import "./Movies.css";

function Movies({ savedMoviesUser, onMovieSave, onMovieDelete }) {
  const [initialMovies, setInitialMovies] = useState([]);
  const [filteredMovies, setFilteredMovies] = useState([]);
  const [moviesToRender, setMoviesToRender] = useState([]);
  const [initialValue, setInitialValue] = useState(0);
  const [moreBtnClick, setMoreBtnClick] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchDone, setIsSearchDone] = useState(false);
  const [isMoreButtonVisible, setIsMoreButtonVisible] = useState(false);
  const [currentViewport, setCurrentViewport] = useState(window.innerWidth);

  const location = useLocation();
  const search = localStorage.getItem("search") || "";
  const checkboxStatus =
    JSON.parse(localStorage.getItem("checkboxStatus")) || false;

  const handleSearch = (searchInput, checkboxStatus) => {
    setMoviesToRender([]);
    localStorage.setItem("search", searchInput);
    localStorage.setItem("checkboxStatus", JSON.stringify(checkboxStatus));
    getInitialMovies(searchInput, checkboxStatus);
  };

  const getInitialMovies = (searchInput, checkboxStatus) => {
    const initialMoviesInLocalStorage = JSON.parse(
      localStorage.getItem("initialMovies")
    );
    if (!initialMoviesInLocalStorage) {
      setIsSearching(true);
      moviesApi
        .getMovies()
        .then((data) => {
          console.log(data);
          setInitialMovies(data);
          localStorage.setItem("initialMovies", JSON.stringify(data));
          filterAndSetMovies(data, searchInput, checkboxStatus);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsSearching(false);
        });
    } else {
      setInitialMovies(initialMoviesInLocalStorage);
      filterAndSetMovies(
        initialMoviesInLocalStorage,
        searchInput,
        checkboxStatus
      );
    }
  };

  const filterAndSetMovies = (movies, searchInput, checkboxStatus) => {
    const searchResults = filterMovies(movies, searchInput, checkboxStatus);
    setFilteredMovies(searchResults);
    setIsSearchDone(true);
  };

  useEffect(() => {
    getInitialMovies(search, checkboxStatus);
  }, []);

  useEffect(() => {
    setCurrentViewport(window.innerWidth);
  }, []);

  useEffect(() => {
    if (currentViewport >= CARD_RENDER_PARAMS_BY_DEVICE.default.width) {
      setInitialValue(CARD_RENDER_PARAMS_BY_DEVICE.default.cards.total);
      setMoreBtnClick(CARD_RENDER_PARAMS_BY_DEVICE.default.cards.more);
    } else if (
      currentViewport < CARD_RENDER_PARAMS_BY_DEVICE.default.width &&
      currentViewport >= CARD_RENDER_PARAMS_BY_DEVICE.desktop.width
    ) {
      setInitialValue(CARD_RENDER_PARAMS_BY_DEVICE.desktop.cards.total);
      setMoreBtnClick(CARD_RENDER_PARAMS_BY_DEVICE.desktop.cards.more);
    } else if (
      currentViewport < CARD_RENDER_PARAMS_BY_DEVICE.desktop.width &&
      currentViewport >= CARD_RENDER_PARAMS_BY_DEVICE.tablet.width
    ) {
      setInitialValue(CARD_RENDER_PARAMS_BY_DEVICE.tablet.cards.total);
      setMoreBtnClick(CARD_RENDER_PARAMS_BY_DEVICE.tablet.cards.more);
    } else {
      setInitialValue(CARD_RENDER_PARAMS_BY_DEVICE.mobile.cards.total);
      setMoreBtnClick(CARD_RENDER_PARAMS_BY_DEVICE.mobile.cards.more);
    }
  }, [currentViewport]);

  useEffect(() => {
    if (filteredMovies.length > 0) {
      if (filteredMovies.length > initialValue) {
        setMoviesToRender(filteredMovies.slice(0, initialValue));
        setIsMoreButtonVisible(true);
      } else {
        setMoviesToRender(filteredMovies);
      }
    }
  }, [filteredMovies, initialValue]);

  const handleMoreButtonClick = () => {
    setMoviesToRender((state) =>
      filteredMovies.slice(0, state.length + moreBtnClick)
    );
  };

  useEffect(() => {
    if (moviesToRender.length === filteredMovies.length) {
      setIsMoreButtonVisible(false);
    }
  }, [moviesToRender, filteredMovies]);

  useEffect(() => {
    const handleResize = () => {
      setCurrentViewport(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="movies">
      <SearchForm
        search={search}
        checkboxStatus={checkboxStatus}
        onSearch={handleSearch}
      />
      {isSearching ? (
        <Preloader />
      ) : (
        isSearchDone &&
        (moviesToRender.length > 0 ? (
          <MoviesCardList
            movies={moviesToRender}
            savedMoviesUser={savedMoviesUser}
            onMovieSave={onMovieSave}
            onMovieDelete={onMovieDelete}
            isMoreButtonVisible={isMoreButtonVisible}
            onMoreButtonClick={handleMoreButtonClick}
          />
        ) : (
          <span className="movies__nothing-found">Ничего не найдено.</span>
        ))
      )}
    </main>
  );
}

export default Movies;
