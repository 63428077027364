const BASE_URL = "https://api.ammosoff.nomoredomains.xyz";
const headers = {
  "Content-Type": "application/json",
};

/* const BASE_URL = "http://localhost:3001";
const headers = {
  "Content-Type": "application/json",
}; */

const getRequestState = (res) => {
  if (res.ok) {
    return res.json();
  }

  return Promise.reject(`Ошибка: ${res.status}`);
};

const request = (url, options) => {
  return fetch(`${BASE_URL}${url}`, options).then(getRequestState);
};

export const register = (name, email, password) => {
  return request("/signup", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      name,
      email,
      password,
    }),
  });
};

export const authorize = (email, password) => {
  return request("/signin", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      email,
      password,
    }),
  });
};

export const checkToken = (token) => {
  return request("/users/me", {
    method: "GET",
    headers: {
      headers,
      Authorization: `Bearer ${token}`,
    },
  });
};
