import { useEffect } from "react";
import "./Popup.css";

function Popup({ children, isOpen, onClose }) {
  useEffect(() => {
    function handleEscapeKey(e) {
      if (e.key === "Escape") {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener("keydown", handleEscapeKey);
      return () => document.removeEventListener("keydown", handleEscapeKey);
    }
  }, [isOpen, onClose]);

  function handleClickOverlay(e) {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }

  return (
    <section
      className={`popup ${isOpen ? "popup_is-opened" : ""}`}
      onMouseDown={handleClickOverlay}
    >
      {children}
    </section>
  );
}

export default Popup;
