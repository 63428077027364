import React from "react";
import { Link } from "react-router-dom";
import "./Login.css";
import HeaderAuthorization from "../HeaderAuthorization/HeaderAuthorization";
import { useFormValidation } from "../../hooks/useFormValidation";

function Login({ title, onLogin, isSuccess, errorStatus: { message, type } }) {
  const { resetForm, values, handleChange, errors, isValid } =
    useFormValidation();
  const isDisabled = !isValid || isSuccess;

  React.useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin({
      email: values.email,
      password: values.password,
    });
  };

  return (
    <section className="login">
      <HeaderAuthorization title={title} />
      <form className="login__form" name="login-form" onSubmit={handleSubmit}>
        <label className="login__form-label">
          <span className="login__form-label-text">E-mail</span>
          <input
            className="login__form-input login__form-input_type_email"
            type="email"
            id="email"
            name="email"
            placeholder=""
            minLength="6"
            maxLength="30"
            required
            value={values.email || ""}
            onChange={handleChange}
          />
          <span
            className={`login__form-error ${
              errors.email && "login__form-error_visible"
            }`}
            id="email-error"
          >
            {errors.email || ""}
          </span>
        </label>

        <label className="login__form-label">
          <span className="login__form-label-text">Пароль</span>
          <input
            className="login__form-input login__form-input_type_password"
            type="password"
            id="password"
            name="password"
            placeholder=""
            minLength="6"
            required
            value={values.password || ""}
            onChange={handleChange}
          />
          <span
            className={`login__form-error ${
              errors.password && "login__form-error_visible"
            }`}
            id="password-error"
          >
            {errors.password
              ? "Пароль должен содержать не менее 6 символов"
              : ""}
          </span>
        </label>

        <span className={`form__message form__message_type_${type}`}>
          {message}
        </span>

        <button
          className={`login__form-submit-button page__button ${
            !isValid && "login__form-submit-button_disabled"
          }`}
          disabled={isDisabled}
          type="submit"
        >
          Войти
        </button>
      </form>

      <p className="login__form-caption">
        Ещё не зарегистрированы?
        <Link className="login__link-signup page__link" to="/signup">
          Регистрация
        </Link>
      </p>
    </section>
  );
}

export default Login;
