import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import FilterCheckbox from "../FilterCheckbox/FilterCheckbox";

import "./SearchForm.css";

function SearchForm({ search, checkboxStatus, onSearch }) {
  const location = useLocation();
  const [error, setError] = useState("");
  const [searchInput, setSearchInput] = useState(
    location.pathname === "/movies" ? search || "" : ""
  );

  const handleSearchChange = (e) => {
    setError("");
    setSearchInput(e.target.value);
  };

  const handleCheckboxChange = (checkboxStatus) => {
    onSearch(searchInput, checkboxStatus);
    localStorage.setItem("checkboxStatus", JSON.stringify(checkboxStatus));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchInput === "") {
      setError("Нужно ввести ключевое слово");
    } else {
      onSearch(searchInput, checkboxStatus);
      setError("");
    }
    location.pathname === "/movies" &&
      localStorage.setItem("search", searchInput);
  };

  return (
    <section className="search-form">
      <form className="search-form__form" onSubmit={handleSubmit} noValidate>
        <input
          className="search-form__input"
          type="text"
          id="searchInput"
          name="movie"
          placeholder="Фильм"
          maxLength="100"
          required
          value={searchInput}
          onChange={handleSearchChange}
        />
        <span
          id="movie-error"
          className="search-form__error search-form__error_visible"
        >
          {error}
        </span>
        <FilterCheckbox
          checkboxStatus={checkboxStatus}
          onCheckboxChange={handleCheckboxChange}
        />
        <button
          className="search-form__button-submit page__button"
          type="submit"
        ></button>
      </form>
    </section>
  );
}

export default SearchForm;
