import "./Promo.css";
import landingLogo from "../../images/landing-logo.svg";

function Promo() {
  return (
    <section className="promo">
      <div className="promo__description">
        <h1 className="promo__title">
          Учебный проект студента факультета Веб-разработки.
        </h1>
        <p className="promo__subtitle">
          Листайте ниже, чтобы узнать больше про этот проект и его создателя.
        </p>
        <a className="promo__link page__link" href="#about-me">
          Узнать больше
        </a>
      </div>
      <img className="promo__img" src={landingLogo} alt="сфера" />
    </section>
  );
}

export default Promo;
