import "./MoviesCard.css";
import { useLocation } from "react-router-dom";
import { conversionDuration } from "../../utils/utils";

function MoviesCard({ movie, savedMoviesUser, onMovieSave, onMovieDelete }) {
  const location = useLocation();

  const isSaved =
    movie.id && savedMoviesUser.some((m) => m.movieId === movie.id);

  const handleSaveMovie = () => {
    if (isSaved) {
      onMovieDelete(savedMoviesUser.filter((m) => m.movieId === movie.id)[0]);
    } else if (!isSaved) {
      onMovieSave(movie);
    }
  };

  const handleDeleteMovie = () => {
    onMovieDelete(movie);
  };

  return (
    <li className="movies-card">
      {location.pathname === "/movies" ? (
        <>
          <a href={movie.trailerLink} rel="noreferrer" target="_blank">
            <img
              className="movies-card__img"
              src={`https://api.nomoreparties.co${movie.image.url}`}
              alt={movie.nameRU}
            ></img>
          </a>
          <div className="movies-card__inner">
            <h2 className="movies-card__title">{movie.nameRU}</h2>
            <button
              className={`movies-card__button page__button ${
                isSaved && "movies-card__button-like"
              }`}
              type="button"
              onClick={handleSaveMovie}
            ></button>
          </div>
        </>
      ) : (
        <>
          <a href={movie.trailerLink} rel="noreferrer" target="_blank">
            <img
              className="movies-card__img"
              src={movie.image}
              alt={movie.nameRU}
            ></img>
          </a>
          <div className="movies-card__inner">
            <h2 className="movies-card__title">{movie.nameRU}</h2>

            <button
              className="movies-card__button movies-card__button-delete page__button"
              type="button"
              onClick={handleDeleteMovie}
            ></button>
          </div>
        </>
      )}

      <p className="movies-card__duration">
        {conversionDuration(movie.duration)}
      </p>
    </li>
  );
}

export default MoviesCard;
